<template>
  <div>
    <button v-if="!isLogin" @click="handleShowLogin">登录</button>
    <button v-if="isLogin" @click="handleLogout">退出</button>

    <user-login
      :show="loginShow"
      :logout="logout"
      @close="handleCloseLogin"
      @login-success="handleLoginSuccess"
      @logout-success="handleLogoutSuccess"
      @input="handleLoginInput"
     />
  </div>
  
</template>

<script>

import UserLogin  from "@/components/UserLogin";

export default {
  components: {
    userLogin: UserLogin
  },
  data() {
    return {
      loginShow: false,
      isLogin: false,
      logout: false
    }
  },
  methods: {
    handleShowLogin() {
      this.loginShow = true
    },
    handleLogout() {
      this.logout = true
    },
    handleCloseLogin() {
      this.loginShow = false
    },
    handleLoginSuccess() {
      this.isLogin = true
    },
    handleLogoutSuccess() {
      this.isLogin = false
    },
    handleLoginInput(e) {
      if (e === false) {
        this.loginShow = false
      }
    }
    
  }

}
</script>

<style>

</style>